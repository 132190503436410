<template>
    <div class="px-3 py-2">
        <v-form ref="addexp">
            <b-sidebar aria-labelledby="sidebar-no-header-title" width="700px" no-header id="pay_expenses" backdrop style="direction:ltr" right title="سداد المصروفات" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:150px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{$parent.lang.pay_expenses}}</span>
                        </div>
                        <div @click="hide" id="expHidation" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                    </div>
                        <v-divider></v-divider>
                        <div class="px-3 py-2" style="direction:rtl">
                            <v-row>
                                <v-col cols="12" md="4" sm="12">
                                    <v-dialog
                                        ref="dialog"
                                        v-model="modal"
                                        :return-value.sync="date"
                                        persistent
                                        width="290px"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            :label="$parent.lang.date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="date"
                                        scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="modal = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.dialog.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" md="4" sm="12">
                                    <v-text-field
                                        v-model="mobile"
                                        type="number"
                                        :label="$parent.lang.mobile"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12">
                                    <v-text-field
                                        v-model="full_name"
                                        :label="$parent.lang.full_name"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12">
                                    <v-select
                                        item-text="text"
                                        item-value="value"
                                        v-model="period"
                                        :items="payperiod"
                                        :label="$parent.lang.payment_period"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4" sm="12">
                                    <v-text-field
                                        v-model="expense_amount"
                                        :label="$parent.lang.expense_amount"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12">
                                    <v-select
                                        item-text="text"
                                        item-value="value"
                                        v-model="paymethod"
                                        :items="paymentmethod"
                                        :label="$parent.lang.payment_method"
                                        @change="showCustody()"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" v-if="showCusto">
                                    <v-select
                                        item-text="text"
                                        item-value="value"
                                        v-model="user"
                                        :items="users"
                                        :label="$parent.lang.users"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </div>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addPayment()" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
export default {
    data() {
        return {
            modal: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            mobile: '',
            full_name: '',
            payperiod: [
                {text:this.$parent.lang.daily, value: 1},
                {text:this.$parent.lang.monthly, value: 2},
                {text:this.$parent.lang.quarterly, value: 3},
                {text:this.$parent.lang.midterm, value: 4},
                {text:this.$parent.lang.yearly, value: 5},
            ],
            period: 1,
            currentExp: [],
            
            paymentmethod: [],
            paymethod: '',
            users: [],
            user: 0,
            showCusto: false
        }
    },
    methods: {
        showCustody(){
            if(this.paymethod == 0){
                this.showCusto = true;
            }else{
                this.showCusto = false;
            }
        },
        getCurretnExp(){
            this.currentExp = this.$parent.currentExp;
        },
        getUsers(){
            const post = new FormData();
            post.append("type", "getCustodyUsers");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[date]',this.date)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response)=>{
                const res = response.data;
                for(let i=0;i<res.results.data.length;i++){
                    this.users.push(
                        {
                            text: res.results.data[i].full_name,
                            value: res.results.data[i].id
                        }
                    )
                }
            })

        },
        addPayment(){
            
            if(this.user == 0 && this.paymethod == -1){
                this.$snotify.create({
                    title: ' ',
                    body: 'يرجى اختيار الموظف لخصم العهدة',
                    config: {
                        position: SnotifyPosition.leftTop,
                        type: SnotifyStyle.error,
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                    }
                })
                return false;
            }
            if(this.paymethod == ''){
                this.$snotify.create({
                    title: ' ',
                    body: 'يرجى اختيار طريقة الدفع',
                    config: {
                        position: SnotifyPosition.leftTop,
                        type: SnotifyStyle.error,
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                    }
                })
                return false;
            }
            if(this.mobile == ''){
                this.$snotify.create({
                    title: ' ',
                    body: 'يرجى ادخال رقم اتصال المستلم',
                    config: {
                        position: SnotifyPosition.leftTop,
                        type: SnotifyStyle.error,
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                    }
                })
                return false;
            }
            if(this.full_name == ''){
                this.$snotify.create({
                    title: ' ',
                    body: 'يرجى ادخال اسم المستلم',
                    config: {
                        position: SnotifyPosition.leftTop,
                        type: SnotifyStyle.error,
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                    }
                })
                return false;
            }
            const post = new FormData();
            post.append("type", "addPayment");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[expenseis]',this.currentExp.expens_type)
            post.append('data[expensesid]',this.currentExp.id)
            post.append('data[date]',this.date)
            post.append('data[mobile]',this.mobile)
            post.append('data[full_name]',this.full_name)
            post.append('data[period]',this.period)
            post.append('data[paymethod]',this.paymethod);
            post.append('data[expense_amount]',this.expense_amount);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response)=>{
                const res = response.data;
                this.$parent.getExpenses()
                document.getElementById('expHidation').click();
                this.$snotify.create({
                    title: ' ',
                    body: 'تم تسجيل سجداد المبلغ بنجاح',
                    config: {
                        position: SnotifyPosition.leftTop,
                        type: SnotifyStyle.success,
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                    }
                })
                this.mobile = '';
                this.full_name = '';
                return false;
            });
        },
        getPaymentMethod(){
            const post = new FormData();
            post.append("type", "getPayTypes");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[y]',1)
            // post.append('data[mobile]',this.mobile)
            // post.append('data[full_name]',this.full_name)
            // post.append('data[period]',this.period)
            // post.append('data[paymethod]',this.paymethod);
            // post.append('data[paymethod]',this.paymethod);
            // post.append('data[expense_amount]',this.expense_amount);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response)=>{
                const res = response.data;
                for(let i=0;i<res.results.data.length;i++){
                    this.paymentmethod.push(
                        {
                            text: this.$parent.lang.langname == "ar" ? res.results.data[i].type_name: res.results.data[i].type_name_en,
                            value: res.results.data[i].id
                        }
                    );
                }
                this.paymentmethod.push(
                    {
                        text: this.$parent.lang.emp_custody,
                        value: 0
                    }
                );
            })
        }
    },
    created() {
        this.getPaymentMethod();
        this.getUsers();
    },
    computed: {
        expense_amount: function(){
            let t = 0;
            if(this.period == 1){ t = this.currentExp.daily; }
            if(this.period == 2){ t = this.currentExp.monthly; }
            if(this.period == 3){ t = this.currentExp.quarter; }
            if(this.period == 4){ t = this.currentExp.half_year; }
            if(this.period == 5){ t = this.currentExp.yearly; }
            return this.$RoundNum(t);
        }
    }
}
</script>